  // Your use of the content in the files referenced here is subject to the terms of the license at https://aka.ms/fabric-assets-license

// tslint:disable:max-line-length

import {
  IIconOptions,
  IIconSubset,
  registerIcons
} from '@uifabric/styling';

export function initializeIcons(
  baseUrl: string = '',
  options?: IIconOptions
): void {
  const subset: IIconSubset = {
    style: {
      MozOsxFontSmoothing: 'grayscale',
      WebkitFontSmoothing: 'antialiased',
      fontStyle: 'normal',
      fontWeight: 'normal',
      speak: 'none'
    },
    fontFace: {
      fontFamily: `"FabricMDL2Icons-10"`,
      src: `url('${baseUrl}fabric-icons-10-c4ded8e4.woff') format('woff')`
    },
    icons: {
      'ViewListGroup': '\uF248',
      'ViewListTree': '\uF249',
      'TriggerAuto': '\uF24A',
      'TriggerUser': '\uF24B',
      'PivotChart': '\uF24C',
      'StackedBarChart': '\uF24D',
      'StackedLineChart': '\uF24E',
      'BuildQueue': '\uF24F',
      'BuildQueueNew': '\uF250',
      'UserFollowed': '\uF25C',
      'ContactLink': '\uF25F',
      'Stack': '\uF26F',
      'Bullseye': '\uF272',
      'VennDiagram': '\uF273',
      'FiveTileGrid': '\uF274',
      'FocalPoint': '\uF277',
      'Insert': '\uF278',
      'RingerRemove': '\uF279',
      'TeamsLogoInverse': '\uF27A',
      'TeamsLogo': '\uF27B',
      'TeamsLogoFill': '\uF27C',
      'SkypeForBusinessLogoFill': '\uF27D',
      'SharepointLogo': '\uF27E',
      'SharepointLogoFill': '\uF27F',
      'DelveLogo': '\uF280',
      'DelveLogoFill': '\uF281',
      'OfficeVideoLogo': '\uF282',
      'OfficeVideoLogoFill': '\uF283',
      'ExchangeLogo': '\uF284',
      'ExchangeLogoFill': '\uF285',
      'Signin': '\uF286',
      'DocumentApproval': '\uF28B',
      'CloneToDesktop': '\uF28C',
      'InstallToDrive': '\uF28D',
      'Blur': '\uF28E',
      'Build': '\uF28F',
      'ProcessMetaTask': '\uF290',
      'BranchFork2': '\uF291',
      'BranchLocked': '\uF292',
      'BranchCommit': '\uF293',
      'BranchCompare': '\uF294',
      'BranchMerge': '\uF295',
      'BranchPullRequest': '\uF296',
      'BranchSearch': '\uF297',
      'BranchShelveset': '\uF298',
      'RawSource': '\uF299',
      'MergeDuplicate': '\uF29A',
      'RowsGroup': '\uF29B',
      'RowsChild': '\uF29C',
      'Deploy': '\uF29D',
      'Redeploy': '\uF29E',
      'ServerEnviroment': '\uF29F',
      'VisioDiagram': '\uF2A0',
      'HighlightMappedShapes': '\uF2A1',
      'TextCallout': '\uF2A2',
      'IconSetsFlag': '\uF2A4',
      'VisioLogo': '\uF2A7',
      'VisioLogoFill': '\uF2A8',
      'VisioDocument': '\uF2A9',
      'TimelineProgress': '\uF2AA',
      'TimelineDelivery': '\uF2AB',
      'Backlog': '\uF2AC',
      'TeamFavorite': '\uF2AD',
      'TaskGroup': '\uF2AE',
      'TaskGroupMirrored': '\uF2AF',
      'ScopeTemplate': '\uF2B0',
      'AssessmentGroupTemplate': '\uF2B1',
      'NewTeamProject': '\uF2B2',
      'CommentAdd': '\uF2B3',
      'CommentNext': '\uF2B4',
      'CommentPrevious': '\uF2B5',
      'ShopServer': '\uF2B6',
      'LocaleLanguage': '\uF2B7',
      'QueryList': '\uF2B8',
      'UserSync': '\uF2B9',
      'UserPause': '\uF2BA',
      'StreamingOff': '\uF2BB',
      'ArrowTallUpLeft': '\uF2BD',
      'ArrowTallUpRight': '\uF2BE',
      'ArrowTallDownLeft': '\uF2BF',
      'ArrowTallDownRight': '\uF2C0',
      'FieldEmpty': '\uF2C1',
      'FieldFilled': '\uF2C2',
      'FieldChanged': '\uF2C3',
      'FieldNotChanged': '\uF2C4',
      'RingerOff': '\uF2C5',
      'PlayResume': '\uF2C6',
      'BulletedList2': '\uF2C7',
      'BulletedList2Mirrored': '\uF2C8',
      'ImageCrosshair': '\uF2C9',
      'GitGraph': '\uF2CA',
      'Repo': '\uF2CB',
      'RepoSolid': '\uF2CC',
      'FolderQuery': '\uF2CD',
      'FolderList': '\uF2CE',
      'FolderListMirrored': '\uF2CF',
      'LocationOutline': '\uF2D0',
      'POISolid': '\uF2D1',
      'CalculatorNotEqualTo': '\uF2D2',
      'BoxSubtractSolid': '\uF2D3'
    }
  };

  registerIcons(subset, options);
}
